export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

// task list
export const TASKS_PER_PAGE = 15;
export const CURRENT_TAX_YEAR = 2023;
export const AVAILABLE_TAX_YEARS = [2023, 2022, 2021, 2020];
export const DEFAULT_STAGES = ['Ops Review', 'Tax Expert Escalations', 'Final Review', 'Filed with IRS'];
export const SUBSTAGE_STAGES = [
  'Ops Review',
  'Tax Expert Escalations',
  'Final Review',
  'Filed with IRS',
  'eng escalation'
];
export const ALL_STAGES = [
  'Not Started',
  'Ops Review',
  'Tax Expert Escalations',
  'Confirm Amounts',
  'Final Review',
  'Filed with IRS',
  'Accepted by IRS',
  'user escalation',
  'eng escalation',
  'Purgatory',
  'DO NOT FILE',
  'Pre-Submit'
];
export const ALL_SUBSTAGES = [
  { value: 'premiumReview', label: 'Premium Ops Review', stage: 'Tax Expert Escalations' },
  { value: 'emailOutbox', label: 'Email Outbox' },
  { value: 'emailInbox', label: 'Email Inbox' },
  { value: 'needToAmend', label: 'Premium Expert Review', stage: 'Tax Expert Escalations' },
  { value: 'multistateReturn', label: 'Multistate Return', stage: 'Ops Review' },
  { value: 'extensionCheck', label: 'Extension Check', stage: 'Final Review' },
  { value: 'eaReview', label: 'EA Review', excludeSubstage: 'blocked' },
  { value: 'readyForReview', label: 'Eng Esc Review', stage: 'eng escalation' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'new', label: 'New', stage: 'Tax Expert Escalations' }
];
export const TASK_LIST_COLS = [
  'new',
  'stage',
  'assignee',
  'user_id',
  'ssn',
  'phone',
  'first_name',
  'last_name',
  'email',
  'updatedAt'
];
export const TASK_EXPORT_FIELDS = [
  'id',
  'stage',
  'user_id',
  'ssn',
  'first_name',
  'last_name',
  'phone',
  'email',
  'assignee',
  'update_reason',
  'last_upload',
  'last_stage_change',
  'ops_status',
  'forms',
  'state',
  'healthcare',
  'multistate',
  'validation_errors',
  'locality',
  'school_district',
  'county',
  'interest_country',
  'debit_acc_info',
  'user_facing_error_type',
  'user_facing_errors',
  'drake_notes',
  'user_esc_reason',
  'state_refund_payment_status',
  'extension_status',
  'createdAt',
  'updatedAt',
  'tagged_reviewers',
  'year',
  'state_drake_notes',
  'user_esc_historic',
  'rejected_esc_historic',
  'prev_stage',
  'dropdown_answers',
  'tax_msg_sent'
];

// task details
export const TP_INFO_COLS = [
  'user_id',
  'phone',
  'email',
  'first_name',
  'last_name',
  'ssn',
  'update_reason',
  'last_upload',
  'last_stage_change',
  'forms',
  'validation_errors',
  'state',
  'locality',
  'school_district',
  'county',
  'federal_amount',
  'multistate_amount',
  'healthcare_taxpayer',
  'healthcare_spouse',
  'healthcare_dependents',
  'multistate_residency',
  'multistate_address',
  'multistate_income',
  'multistate_expenses',
  'debit_acc_info',
  'tax_msg_sent'
];
export const FORM_UPLOAD_COLS = [
  { displayName: 'File Name', getValue: (_, file) => file.file_name },
  { displayName: 'Collection Type', getValue: (attempt, _) => attempt.collectionType },
  { displayName: 'Status', getValue: (attempt, _) => attempt.status },
  { displayName: 'Updated At', getValue: (attempt, _) => new Date(attempt.updatedAt).toLocaleString('en-US') }
];
export const SUBSTAGE_FIELDS = [
  { label: 'Premium Ops Review', name: 'premiumReview' },
  { label: 'Email Inbox', name: 'emailInbox' },
  { label: 'Premium Expert Review', name: 'needToAmend' },
  { label: 'Multistate Return', name: 'multistateReturn', disabled: true },
  { label: 'EA Review', name: 'eaReview', disabled: true },
  { label: 'Extension Check', name: 'extensionCheck' },
  { label: 'Blocked', name: 'blocked', special: true },
  { label: 'Email Outbox', name: 'emailOutbox', special: true },
  { label: 'New', name: 'new' }
];
export const USER_ESC_TYPES = [
  { label: 'Keeper review', value: 'standard' },
  { label: 'Rejected by IRS', value: 'rejected by irs' },
  { label: 'Unable to file', value: 'DNF' }
];
export const EXTENSION_STATUSES = [
  { label: '', value: '' },
  { label: 'Filed', value: 'filed' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Error', value: 'error' },
  { label: 'Not in Drake', value: 'notInDrake' }
];
export const ACTION_STAGE_MAP = {
  'User Escalation': [
    'ops review',
    'final review',
    'tax expert escalations',
    'filed with irs',
    'accepted by irs',
    'eng escalation',
    'user escalation'
  ],
  'Confirm Amounts': ['ops review', 'tax expert escalations', 'eng escalation'],
  'Confirm Amounts (no text/email)': ['final review', 'eng escalation'],
  'Update amounts': ['confirm amounts', 'eng escalation'],
  'Filed with IRS': ['final review', 'tax expert escalations'],
  'Accepted by IRS': ['filed with irs', 'tax expert escalations'],
  'Move to Ops Review': ['tax expert escalations'],
  'Move to Final Review': ['tax expert escalations'],
  'Move to Tax Expert Esc': ['ops review', 'final review', 'filed with irs', 'accepted by irs'],
  'Move to eng esc': ['ops review', 'final review', 'tax expert escalations'],
  'Run Drake upload': ['not started', 'eng escalation'],
  'Unlock user': ['ops review', 'eng escalation', 'do not file', 'confirm amounts'],
  'DO NOT FILE': ['not started', 'ops review', 'tax expert escalations', 'eng escalation']
};

export const RETURN_DOWNLOAD_STAGES = [
  'Ops Review',
  'Tax Expert Escalations',
  'Confirm Amounts',
  'Final Review',
  'Filed with IRS',
  'Accepted by IRS'
];

export function serverUrl() {
  if (REACT_APP_ENV === 'development') {
    return 'http://localhost:5001/';
  } else if (REACT_APP_ENV === 'staging') {
    return 'https://keeper-backend-staging.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-2') {
    return 'https://keeper-backend-staging-2.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-3') {
    return 'https://keeper-backend-staging-3.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-4') {
    return 'https://keeper-backend-staging-4.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-5') {
    return 'https://keeper-backend-staging-5.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-6') {
    return 'https://keeper-backend-staging-6.keepertax.com/';
  } else if (REACT_APP_ENV === 'production') {
    return 'https://keeper-backend.keepertax.com/';
  }
}

export function appUrl() {
  if (REACT_APP_ENV === 'development') {
    return 'http://localhost:3003';
  } else if (REACT_APP_ENV === 'staging') {
    return 'https://qdeck-staging.keepertax.com';
  } else if (REACT_APP_ENV === 'staging-2') {
    return 'https://qdeck-staging-2.keepertax.com';
  } else if (REACT_APP_ENV === 'staging-3') {
    return 'https://qdeck-staging-3.keepertax.com';
  } else if (REACT_APP_ENV === 'staging-4') {
    return 'https://qdeck-staging-4.keepertax.com';
  } else if (REACT_APP_ENV === 'staging-5') {
    return 'https://qdeck-staging-5.keepertax.com';
  } else if (REACT_APP_ENV === 'staging-6') {
    return 'https://qdeck-staging-6.keepertax.com';
  } else if (REACT_APP_ENV === 'production') {
    return 'https://qdeck.keepertax.com';
  }
}
